import axios from 'axios';
import constants from "../Utils/Constants.js";
import cathodeVersions from '@amzn/cathode-versions-javascript';
import {getCandidatePortalUrl} from "../Utils/CountryConstant.js";

let counter = 0;
let orgBaseURL
let candidatePortalURL
let cloudfrontS3Distribution

let orgAxios;
let candidateAxios
let stage, country

const init = () => {
  return new Promise(async (resolve) => {
    orgBaseURL = sessionStorage.getItem('orgBaseURL');
    cloudfrontS3Distribution = sessionStorage.getItem('cloudfrontS3Distribution');
    candidatePortalURL = sessionStorage.getItem('careerPortalURL');

    /* istanbul ignore if */
    if (!(orgBaseURL && candidatePortalURL && cloudfrontS3Distribution) && process.env.REACT_APP_ENV !== 'unittesting') {
      let res=null;
      try{
        res = await axios.get('/settings.json');
      } catch(err){
        res = err;
      } 
      if (res?.data?.phoPortalProxy) {
        orgBaseURL = res.data.phoPortalProxy
        candidatePortalURL = res.data.careerPortalProxy
        cloudfrontS3Distribution = `https://${res.data.cloudfrontS3DistributionName}`;
        stage = res.data.stage;
        country = res.data.country;
      } else {
        orgBaseURL = process.env.REACT_APP_ORG_BASE_URL
        candidatePortalURL = process.env.REACT_APP_CANDIDATE_PORTAL_URL
        cloudfrontS3Distribution = process.env.REACT_APP_CLOUDFRONT_S3_DISTRIBUTION
        stage = process.env.REACT_APP_STAGE;
        country = process.env.REACT_APP_COUNTRY;
      }

      sessionStorage.setItem('orgBaseURL', orgBaseURL);
      sessionStorage.setItem('candidatePortalURL', candidatePortalURL);
      sessionStorage.setItem('cloudfrontS3Distribution', cloudfrontS3Distribution);
      sessionStorage.setItem(constants.SPECTROMETER.STAGE, stage);
      sessionStorage.setItem('countryCode',country);
    }
    setAxios();
    resolve();
  })

}

const setAxios = () => {
  counter = 0;

  if (!orgAxios) {
    orgAxios = axios.create({
      withCredentials: true,
      baseURL: orgBaseURL,
      timeout: 180000
    })
    orgAxios.interceptors.request.use(handleSuccessRequest, handleErrorRequest)
    orgAxios.interceptors.response.use(handleSuccess, handleError)
  }
  if (!candidateAxios) {
    candidateAxios = axios.create({
      withCredentials: true,
      baseURL: candidatePortalURL,
      timeout: 180000
    })
    candidateAxios.interceptors.request.use(handleSuccessRequestCandidatePortal, handleErrorRequest)
    candidateAxios.interceptors.response.use(handleSuccess, handleError)
  }
}

const handleSuccessRequest = (request) => {
  document.body.classList.add('loading_indicator')
  counter = counter + 1;
  return request
}

const handleSuccessRequestCandidatePortal = (request) => {
  document.body.classList.add('loading_indicator')
  counter = counter + 1;
  request.headers['idtoken'] = sessionStorage.getItem('idtoken');
  request.headers['accesstoken'] = sessionStorage.getItem('accesstoken');
  request.headers['country'] = sessionStorage.getItem('countryCode');
  return request
}

const handleErrorRequest = (error) => {
  counter = counter - 1;
  if (counter === 0) {
    document.body.classList.remove('loading_indicator')
  }
  return Promise.reject(error)
}

const handleSuccess = (response) => {
  counter = counter - 1;
  if (counter === 0) {
    document.body.classList.remove('loading_indicator')
  }
  return response
}

const handleError = (error) => {
  counter = counter - 1;
  if (counter === 0) {
    document.body.classList.remove('loading_indicator')
  }
  if (error.message === 'Network Error') {
    // The user doesn't have internet
    return Promise.reject(error.response)
  }
  try {
    switch (error.response.status) {
      case 400:
        document.body.classList.remove('loading_indicator')
        break
      case 401:
        //Un authorized
        document.body.classList.remove('loading_indicator')
        break
      case 403:
        //Un authorized
        document.body.classList.remove('loading_indicator')
        window.alert("Invalid Session");
        window.location.href = getCandidatePortalUrl(sessionStorage.getItem('stage'));
        sessionStorage.clear();
        break
      case 404:
        // Show 404 page
        break
      case 500:
        // Serveur Error redirect to 500
        break
      default:
        Promise.reject(error.response)
        break
    }
  } catch (e) {
  }
  return Promise.reject(error.response)
}

stage = sessionStorage.getItem(constants.SPECTROMETER.STAGE);
//User analytics integration(Spectrometer)
const cathodeConfig = {
  siteName: constants.SPECTROMETER.SITE_NAME,
  appName: (stage !== null &&  stage !== undefined) ? constants.SPECTROMETER.APP_NAME+"-"+stage :
      constants.SPECTROMETER.APP_NAME
}

init()

/* eslint no-eval: "off" */
const scriptTags = cathodeVersions.getSpectrometerScriptTags(cathodeConfig, true)
const rawBoomerangScript = scriptTags.boomerangLoaderScript
const boomerangScript = rawBoomerangScript.substring(8,rawBoomerangScript.length - 9);

const rawListenerScript = scriptTags.listenerScripts
const listenerScript = rawListenerScript.substring(8,rawListenerScript.length - 9);

const rawCathodeScript = scriptTags.cathodeScript
const cathodeScript = rawCathodeScript.substring(8,rawCathodeScript.length - 9);

window.eval(boomerangScript);
window.eval(listenerScript);
window.eval(cathodeScript);

export {
  orgAxios,
  orgBaseURL,
  candidateAxios,
  candidatePortalURL,
  cloudfrontS3Distribution,
  init,
  setAxios
}