export const CANDIDATE_PORTAL_BASE_URL = {
    alpha: {
        AE : "https://alpha.careers.warehousejobsuae.com/",
        SA : "https://alpha.careers.warehousejobsksa.com/",
        EG : "https://alpha.careers.warehousejobseg.com/",
        IN : "https://alpha.careers.hvhindia.in/"
    },
    beta: {
        SA : "https://beta.careers.warehousejobsksa.com/",
        EG : "https://beta.careers.warehousejobseg.com/",
        AE : "https://beta.careers.warehousejobsuae.com/",
        IN : "https://beta.careers.hvhindia.in/"
    },
    gamma: {
        SA : "https://gamma.careers.warehousejobsksa.com/",
        AE : "https://gamma.careers.warehousejobsuae.com/",
        EG : "https://gamma.careers.warehousejobseg.com/",
        IN : "https://gamma.careers.hvhindia.in/"
    },
    prod: {
        SA : "https://careers.warehousejobsksa.com/",
        AE : "https://careers.warehousejobsuae.com/",
        EG : "https://careers.warehousejobseg.com/",
        IN : "https://careers.hvhindia.in/"
    }
}
export let isMENACountry = () =>{
    const countryCode = sessionStorage.getItem('countryCode');
    return countryCode === "AE" || countryCode === "EG" || countryCode === "SA";
}

export function getCandidatePortalUrl(stage){
    const countryCode = sessionStorage.getItem('countryCode');
    return CANDIDATE_PORTAL_BASE_URL[stage][countryCode];
}