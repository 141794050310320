export const MMM_TO_MM = {
    'Jan' : '01',
    'Feb' : '02',
    'Mar' : '03',
    'Apr' : '04',
    'May' : '05',
    'Jun' : '06',
    'Jul' : '07',
    'Aug' : '08',
    'Sep' : '09',
    'Oct' : '10',
    'Nov' : '11',
    'Dec' : '12',
    'JAN' : '01',
    'FEB' : '02',
    'MAR' : '03',
    'APR' : '04',
    'MAY' : '05',
    'JUN' : '06',
    'JUL' : '07',
    'AUG' : '08',
    'SEP' : '09',
    'OCT' : '10',
    'NOV' : '11',
    'DEC' : '12',
}

export function sortModulesOnPriority(modules){
    return modules.sort(function(a, b) {
        return parseInt(a.priority) - parseInt(b.priority);
    });
}

export function getProperName(body){
    const firstName = body.firstName?body.firstName:"";
    const middleName = body.middleName?body.middleName:"";
    const lastName = body.lastName?body.lastName:"";
    return firstName+" "+ middleName + " " + lastName;
}