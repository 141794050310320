import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StencilProvider } from "@amzn/stencil-react-components/context";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
// import { setAxios } from './api/axios';

// setAxios();

ReactDOM.render(
    <StencilProvider>
        <BrowserRouter>
            <Suspense fallback="Loading...">
                <App />
            </Suspense>
        </BrowserRouter>
    </StencilProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
