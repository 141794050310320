import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ICU from "i18next-icu";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import { getDefaultLocale } from "./Utils/TranslationUtils.ts";

const findGetParameter = (parameterName) => {
  var result = null,
      tmp = [];
      window.location.search
      .substring(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
  return result;
}

const getLanguageFromUrl = () =>{
  let language = findGetParameter('locale') ? findGetParameter('locale'): getDefaultLocale();
  return language.replace('_','-');
}
// i18n.on('initialized', ()=>{ resolve(i18n)})
i18n.use(resourcesToBackend((language, namespace, callback) => {
  import(`../translations/appStrings-${language}.puff.json`)
    .then((resources) => {
      callback(null, JSON.parse(JSON.stringify(resources.default.resources)));
    })
    .catch((error) => {
      callback(error, "");
    });
}))
  .use(ICU)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      debug: process.env.NODE_ENV !== "production",
      fallbackLng: getLanguageFromUrl(),
      interpolation: {
        // not needed for react as it escapes by default
        escapeValue: false
      },
      load: "currentOnly",
      detection: {
        // order and from where user language should be detected
        order: ["cookie"],
        lookupCookie: "pho-locale"
      }
    }
  );

export default i18n;
