import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spacer } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useBreakpoints } from "@amzn/stencil-react-components/responsive";
import { Radio, Checkbox, RadioColorScheme, CheckboxColorScheme } from '@amzn/stencil-react-components/form';
import {
    IconAlertCircleFill,
    IconCheck,
    IconCross,
    IconSize,
    IconArrowRight
} from '@amzn/stencil-react-components/icons';
import PlayerScreen from './PlayerScreen';
import './ModulePage.css';
import { ProgressBar, Status } from '@amzn/stencil-react-components/progress-bar';
import { View } from '@amzn/stencil-react-components/layout';
import constants from '../../Utils/Constants';
import { translate as t } from '../../Utils/TranslationUtils';

interface IModule {
    moduleId: string;
    moduleName: string;
    description: string;
    resourceUrl: string;
    resourceUrlMobile: string;
    resourceAltText: string;
    contents: Array<IContent>;
    module_completed: boolean;
    status: string;
    Index?: number;
}

interface IContent {
    answer: any;
    contentData: Array<string>;
    contentId: string;
    contentName: string;
    contentType: string;
    description: string;
    question_completed: boolean;
    status: string;
    Index?: number;
    input_value?: string;
}

interface IModuleProps {
    state: Array<IModule>;
    activeMenu: IModule;
    activeSubMenu: IContent;
    updateModuleStatus: Function;
    goBack: Function;
}

export default function ModulePage(props: IModuleProps) {
    const { matches: deviceResolution } = useBreakpoints();
    // const [moduleData, setModuleData] = useState<Array<IModule>>(props.state.slice(1, props.state.length - 1));
    // const [currentModule, setcurrentModule] = useState(0);
    // const [currentQuestion, setcurrentQuestion] = useState(0);
    const [wrongAnswer, setWrongAnswer] = useState(false);
    const [isClicked, setIsClicked] = useState<string[]>([]);
    const [isWrong, setIsWrong] = useState<string[]>([]);
    const [isCorrect, setIsCorrect] = useState<string[]>([]);
    const [activeMenu, setActiveMenu] = useState(props.activeMenu);
    const [activeSubMenu, setActiveSubMenu] = useState(props.activeSubMenu);
    const [isBrokenImage, setIsBrokenImage] = useState(false);
    // const [enableNext, setEnableNext] = useState<Boolean>(false);
    // const [enableBack, setEnableBack] = useState<Boolean>(false);

    useEffect(() => {
        if (props.activeMenu?.moduleId) {
            setIsCorrect([]);
            setIsClicked([]);
            let tsubmenu = props.activeSubMenu;
            if (props.activeSubMenu && props.activeSubMenu.status === constants.PHO.STATUS.COMPLETED) {
                if (props.activeSubMenu["input_value"]) {
                    setIsCorrect([props.activeSubMenu.input_value]);
                    setIsClicked([props.activeSubMenu.input_value]);
                } else {
                    setIsCorrect(props.activeSubMenu.answer);
                    setIsClicked(props.activeSubMenu.answer);
                }
            } else if (tsubmenu && props.activeSubMenu.question_completed) {
                tsubmenu.question_completed = false;
            }
            setActiveMenu(props.activeMenu);
            setActiveSubMenu(tsubmenu);
        }
    }, [props.activeMenu, props.activeSubMenu])

    // useEffect(() => {
    //     if (props.state?.length) {
    //         setModuleData(props.state.slice(1, props.state.length - 1));
    //     }
    // }, [props.state])

    const chooseAnswer = (value: string) => {
        setWrongAnswer(false);
        setIsWrong([]);
        setIsCorrect([]);
        let selectedOptions = [];
        selectedOptions.push(value);
        setIsClicked(selectedOptions);
    }

    const chooseMultiAnswers = (value: string) => {
        setWrongAnswer(false);
        let selectedOptions = [...isClicked];
        let selectedIndex = [...isClicked].indexOf(value);
        if (selectedIndex > -1) {
            selectedOptions.splice(selectedIndex, 1);
            setIsClicked(selectedOptions);
        } else {
            selectedOptions.push(value);
            setIsClicked(selectedOptions);
        }
        // if (ele.currentTarget.checked) {
        //     let selectedOptions = [];
        //     selectedOptions = [...isClicked];
        //     selectedOptions.push(ele.target.value);
        //     setIsClicked(selectedOptions);
        // }
        // else {
        //     let selectedOptions = [];
        //     selectedOptions = [...isClicked];
        //     let i = selectedOptions.indexOf(ele.target.value);
        //     if (i > -1) {
        //         selectedOptions.splice(i, 1);
        //     }
        //     setIsClicked(selectedOptions);
        // }
    }

    const checkAnswer = () => {
        let flag = false;
        let tempRight = [];
        let tempWrong = [];
        if (activeSubMenu.contentType === constants.INPUT_TYPES.USER_INPUT_SINGLE_SELECT) {
            let selectedValues = isClicked[0];
            flag = true;
            tempRight.push(selectedValues)
            // if (activeSubMenu.answer.includes(selectedValues)) {
            // }
            // else {
            //     flag = false;
            //     tempWrong.push(selectedValues)
            // }
        } else if (activeSubMenu.contentType === constants.INPUT_TYPES.SINGLE_SELECT) { // single answer
            let selectedValues = isClicked[0];
            if (activeSubMenu.answer.includes(selectedValues)) {
                flag = true;
                tempRight.push(selectedValues)
            }
            else {
                flag = false;
                tempWrong.push(selectedValues)
            }
        }
        else { // multiple answers
            let selectedValues = []
            selectedValues = [...isClicked];
            let count1 = 0;
            let count2 = 0;
            selectedValues.forEach(ele => {
                if (activeSubMenu.answer.includes(ele)) {
                    tempRight.push(ele);
                    count1++;
                }
                else {
                    tempWrong.push(ele);
                    count2++;
                }
                return activeSubMenu.answer.includes(ele);
            });

            if (count1 === activeSubMenu.answer.length && count2 === 0) {
                flag = true;
            }
            else {
                flag = false;
            }
        }

        if (!flag) {
            tempWrong.length > 0 ? setWrongAnswer(true) : tempRight.length !== activeSubMenu.answer.length ? setWrongAnswer(true) : setWrongAnswer(false);
            tempWrong.length > 0 ? setIsWrong(tempWrong) : setIsWrong([]);
            tempRight.length > 0 ? setIsCorrect(tempRight) : setIsCorrect([]);
        }
        else {
            setWrongAnswer(false);
            tempWrong.length > 0 ? setWrongAnswer(true) : setWrongAnswer(false);
            tempWrong.length > 0 ? setIsWrong(tempWrong) : setIsWrong([]);
            tempRight.length > 0 ? setIsCorrect(tempRight) : setIsCorrect([]);

            // completed and go to next
            // setIsClicked([]);
            // setIsCorrect([]);
            // setIsWrong([]);
            activeSubMenu.question_completed = true;
            // let sample = moduleData;
            // if ((moduleData.length - 1) === currentModule && (moduleData[currentModule].contents.length - 1) === currentQuestion) {
            //     sample[currentModule].module_completed = true;
            //     sample[currentModule].contents[currentQuestion].question_completed = true;
            //     setModuleData(sample);
            //     props.onMenuChange("feedback");
            //     return false;
            // }
            // else {
            //     if ((moduleData[currentModule].contents.length - 1) === currentQuestion) {
            //         sample[currentModule].module_completed = true
            //         sample[currentModule].contents[currentQuestion].question_completed = true;
            //         setModuleData(sample);
            //         setcurrentModule(currentModule + 1);
            //         setcurrentQuestion(0);
            //     }
            //     else {
            //         sample[currentModule].contents[currentQuestion].question_completed = true;
            //         setModuleData(sample);
            //         setcurrentQuestion(currentQuestion + 1);
            //     }
            // }
        }
    }

    const goNext = () => {
        let userInput = '';
        if (activeSubMenu && activeSubMenu.contentType.includes("USER_INPUT")) {
            userInput = activeSubMenu.contentData.findIndex(ans=>ans===isClicked[0]) + '';
        }
        setIsClicked([]);
        setIsCorrect([]);
        setIsWrong([]);
        // if ((moduleData.length - 1) === currentModule && (activeMenu.contents.length - 1) === currentQuestion) {
        //     props.onMenuChange("feedback");
        // }
        // else if (activeMenu?.contents?.length) {
        //     if ((activeMenu.contents.length - 1) === currentQuestion) {
        //         setcurrentModule(currentModule + 1);
        //         setcurrentQuestion(0);
        //         props.onMenuChange(activeMenu.moduleId)
        //     }
        //     else {
        //         setcurrentQuestion(currentQuestion + 1);
        //         props.onMenuChange(activeSubMenu.contentId)
        //     }
        // } else {
        //     props.onMenuChange(moduleData[currentModule + 1].moduleId)
        //     setcurrentModule(currentModule + 1);
        //     setcurrentQuestion(0);
        // }
        props.updateModuleStatus(userInput);
    }

    const resetAll = () => {
        setIsClicked([]);
        setIsCorrect([]);
        setIsWrong([]);
        setWrongAnswer(false);
        let x: any = document.querySelectorAll('input');
        x.forEach((e: any) => {
            e.disabled = false;
            e.checked = false;
        });
    }

    const goback = () => {
        resetAll();
        props.goBack();
    }

    return (
        <View style={{ width: (deviceResolution.l || deviceResolution.xl) ? '74%' : '100%', height: '100%', background: 'white', zIndex: 5, paddingBottom: (deviceResolution.l || deviceResolution.xl) ? '0px' : '70px' }} className='sidenav'>
            <Col width={"100%"} gridGap={"S400"} style={{ padding: (deviceResolution.l || deviceResolution.xl) ? "2.5rem 8rem" : "1rem 1rem", paddingBottom: "100px" }}>
                {
                    (deviceResolution.l || deviceResolution.xl) &&
                    <Col width="100%">
                        <Row width="100%">
                            <Text fontSize="T300" fontWeight="regular" color="neutral90">{activeMenu?.moduleName} {activeSubMenu ? `/ ${activeSubMenu.contentName} ${activeSubMenu.Index ? activeSubMenu.Index + 1 : 1}` : ""}</Text>
                        </Row>
                        <Col width="25%" margin="10px 0" id='progress-bar'>
                            <ProgressBar
                                aria-labelledby="status-indicator-1"
                                progress={activeMenu?.Index ? ((activeMenu.Index - 1) / (props.state.length - 1)) : 0}
                                status={Status.Positive}
                            />
                        </Col>
                    </Col>
                }
                <Row width="100%" height="auto" id='content-image'>
                    {   //We are hiding player screen from the feedback module
                        (props.state && props.state[props.state.length-1]?.moduleId !== activeMenu?.moduleId && activeMenu?.resourceUrl !== undefined) ? activeMenu?.resourceUrl.split(".")[1] === 'mp4' ? <PlayerScreen path={(deviceResolution.l || deviceResolution.xl) ? activeMenu.resourceUrl : activeMenu.resourceUrlMobile} altText={activeMenu.resourceAltText} /> : <Container alignItems="center" style={{ marginTop: "10px" }}>
                            {isBrokenImage ? <Text fontSize={"T200"} fontWeight="regular" color="neutral90">{activeMenu.resourceAltText ? activeMenu.resourceAltText : t("PHO-img-alt", "Unable to show the img: Kindly reach out to the recruiter from the staffing agency to obtain this information")}</Text> : <img src={`${sessionStorage.getItem('cloudfrontS3Distribution')}/${(deviceResolution.l || deviceResolution.xl) ? activeMenu.resourceUrl : activeMenu.resourceUrlMobile}`}
                                onError={() => { setIsBrokenImage(true); }}
                                alt={activeMenu.resourceAltText ? activeMenu.resourceAltText : t("PHO-img-alt", "Unable to show the img: Kindly reach out to the recruiter from the staffing agency to obtain this information")} style={{ width: '100%' }} />
                            }</Container> : <></>
                    }
                </Row>
                {(activeSubMenu?.description || activeMenu?.description) && <Row>
                    <Text>
                        {
                            activeSubMenu ? activeSubMenu.description : activeMenu.description ? t("PHO-note", "Note: ") + " " + activeMenu.description : ""
                        }
                    </Text>
                </Row>}
                <Col gridGap="S200" id="OptionsField">
                    {
                        activeSubMenu &&
                        activeSubMenu.contentData.map((current: any, index: number) => (
                            <Row key={index} style={{ cursor: "pointer" }} justifyContent='space-between' border="2px solid #8B96A3" padding="S200" className={isClicked.includes(current) ? (isCorrect.includes(current) ? "correct-tab" : (isWrong.includes(current) ? "wrong-tab" : "selected-tab")) : ""} onClick={() => (activeSubMenu.contentType === constants.INPUT_TYPES.MULTI_SELECT) ? chooseMultiAnswers(current) : chooseAnswer(current)}>
                                <View style={{ width: '92%' }}><Text fontSize={"T200"} id={"label-option-" + index} className={isClicked.includes(current) ? (isCorrect.includes(current) ? "correct-label" : (isWrong.includes(current) ? "wrong-label" : "selected-label")) : ""}>{current}</Text></View>
                                {
                                    activeSubMenu.contentType === constants.INPUT_TYPES.MULTI_SELECT ?
                                        !isCorrect.includes(current) && !isWrong.includes(current) && <Checkbox onChange={() => { }} id={"checkbox-option-" + index} data-testid={"checkbox-option-" + index} disabled={activeSubMenu.question_completed ? true : false} checked={isClicked.includes(current) ? true : false} key={"checkbox-option-" + index} name={"question" + index} colorScheme={isClicked.includes(current) ? CheckboxColorScheme.Inverted : CheckboxColorScheme.Default} value={current} />
                                        :
                                        !isCorrect.includes(current) && !isWrong.includes(current) && <Radio onChange={() => { }} id={"radio-option-" + index} data-testid={"radio-option-" + index} disabled={activeSubMenu.question_completed ? true : false} checked={isClicked.includes(current) ? true : false} key={"radio-option-" + index} name={"question" + index} colorScheme={isClicked.includes(current) ? RadioColorScheme.Inverted : RadioColorScheme.Default} value={current} />
                                }
                                {isCorrect.includes(current) &&
                                    <div>
                                        <IconCheck size={IconSize.Medium} color="white" />
                                    </div>
                                }
                                {isWrong.includes(current) &&
                                    <div>
                                        <IconCross size={IconSize.Small} color="white" />
                                    </div>
                                }
                            </Row>
                        ))
                    }
                </Col>
                {
                    wrongAnswer && (
                        <Row style={{ background: "#fef0ef", padding: "10px 5px", borderLeft: "8px solid #BB2929" }} id='incorrect-answer-banner'>
                            <Spacer height="S300" />
                            <IconAlertCircleFill size={IconSize.Small} color="#BB2929" margin="4px 5px 0 0" />
                            <Text><span style={{ color: '#BB2929', fontWeight: "bold" }}>{t("PHO-incorrect", "Incorrect")},</span> {t("PHO-incorrect-desc", "please choose the appropriate answer.")}</Text>
                        </Row>
                    )
                }
                <Row justifyContent="space-between" style={{ marginTop: (deviceResolution.l || deviceResolution.xl) ? "16px" : "0px" }}>
                    {(activeMenu.Index && activeMenu.Index > 1) && <Button variant={ButtonVariant.Secondary} style={{ padding: "0.5rem 3.5rem", paddingLeft: "3rem", fontWeight: "normal" }} onClick={goback} id='back-button'>{t("PHO-btn-back", "Back")}</Button>}
                    {activeSubMenu ? <Button variant={ButtonVariant.Primary} style={{ padding: "0.5rem 3.5rem", paddingRight: "3rem", fontWeight: "normal" }}
                        disabled={!activeSubMenu.question_completed && isClicked.length === 0}
                        onClick={activeSubMenu.question_completed ? goNext : isWrong.length > 0 ? resetAll : checkAnswer} id='continue-button'>{activeSubMenu.question_completed ? <Row>{t("PHO-btn-next", "Next")}<IconArrowRight display="inline" margin={{ left: 10, top: 4 }} size={IconSize.ExtraSmall} /></Row> : isWrong.length > 0 ? t("PHO-btn-reset", "Reset") : t("PHO-btn-continue", "Continue")}</Button> :
                        <Button variant={ButtonVariant.Primary} onClick={goNext} style={{ padding: "0.5rem 3.5rem", paddingRight: "3rem", fontWeight: "normal" }} id='next-button'><Row>{t("PHO-btn-next", "Next")}<IconArrowRight display="inline" margin={{ left: 10, top: 4 }} size={IconSize.ExtraSmall} /></Row></Button>}
                </Row>
            </Col>
        </View>
    )
}