import React, { useEffect, useState } from 'react';
import { Text } from '@amzn/stencil-react-components/text';
import { translate } from '../../Utils/TranslationUtils';
import {
    PageHeader,
    PageHeaderDropdown,
    PageHeaderDropdownButton,
    PageHeaderLink,
} from '@amzn/stencil-react-components/page';
import { Spacer } from '@amzn/stencil-react-components/layout';
import i18n from '../../i18n';
import { IconGlobe } from '@amzn/stencil-react-components/icons';
import { useBreakpoints } from "@amzn/stencil-react-components/responsive";
import {isMENACountry} from "../../Utils/CountryConstant";

const default_languages = [
    { id: 'en-US', name: 'English' }
]

export default function HeaderTab(props: any) {
    // @ts-ignore
    const [languages, setLanguages] = useState(default_languages);
    const [language, setLanguage] = useState(i18n.language.replace('-', '_'));
    const { matches: deviceResolution } = useBreakpoints();

    useEffect(() => {
        if (localStorage.getItem('PHO_LANGUAGES')) {
            // @ts-ignore
            let l = JSON.parse(localStorage.getItem('PHO_LANGUAGES'));
            setLanguages(l);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem('PHO_LANGUAGES')]);

    /* istanbul ignore next */
    const changeLanguage = (item: any) => {
        setLanguage(item.id)
        if (item.id.replace('_', "-") !== i18n.language) {
            const searchParams = new URLSearchParams(window.location.search);
            if (searchParams.get("locale")) {
                searchParams.append("local", item.id);
                window.location.replace(window.location.href.replace(`?locale=${searchParams.get("locale")}`, `?locale=${item.id}`));
            } else {
                window.location.replace(window.location.href.replace('?phoId', `?locale=${item.id}&phoId`));
            }
        }
    }

    return (
        <PageHeader isFixed={true} isFullWidth={true} hasShadow={true}>
            <nav aria-label="Site" style={{ display: 'flex', flex: (deviceResolution.m || deviceResolution.s) ? 0.6 : 0.3 }}>
                <PageHeaderLink href="#" style={{ padding: "0" }}><Text fontSize={"T400"}>{translate('PHO-Common-title', '')}</Text></PageHeaderLink>
            </nav>
            <Spacer flex={(deviceResolution.m || deviceResolution.s) ? 0.1 : 0.6} />
            { !isMENACountry() &&
                <nav aria-label="Site" style={{ display: 'flex', flex: (deviceResolution.m || deviceResolution.s) ? 0.3 : 0.1 }}>
                    <PageHeaderDropdown icon={<IconGlobe title="Change Language" />}
                                        items={languages.map(item => <PageHeaderDropdownButton onClick={() => { changeLanguage(item) }} id={'language-' + item.name}>{item.name}</PageHeaderDropdownButton >)}>
                        {languages.find(l => l.id === language)?.name}
                    </PageHeaderDropdown>
                </nav>}
        </PageHeader>);
}