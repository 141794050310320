export enum Locale {
    enGB = "en-GB",
    enUS = "en-US",
    esUS = "es-US",
    esMX = "es-MX",
    jaJP = "ja-JP",
}

export enum CountryCode {
    MX = "MX",
    US = "US",
    CA = "CA",
    UK = "UK",
    JP = "JP"
}