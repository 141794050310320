import i18next, { StringMap, TOptions } from "i18next";
import { CountryCode, Locale } from "../Types/Common";
import Cookies from "js-cookie";
import constants from "./Constants";

export const getLocale = (): Locale => {
    const locale: string = Cookies.get(constants.TRANSLATE.PHO_LOCALE) || "";

    return locale ? locale as Locale : getDefaultLocale();
};

export const getDefaultLocale = (): Locale => {
    const countryCode = "{{Country}}" as CountryCode;

    switch (countryCode) {
        case CountryCode.MX:
            return Locale.esMX;
        case CountryCode.UK:
            return Locale.enGB;
        case CountryCode.JP:
            return Locale.jaJP;
        default:
            return Locale.enUS;
    }
};


export const translate = (stringId: string, defaultString: string, options?: TOptions<StringMap> | string) => {

    // You can pass an array of keys to lookup.
    // The last one in the list will end up being the default value being displayed if no keys are found.
    // const locale = getLocale({});
    // const locale = getLocale();
    // const keyString = i18next.exists([ "en-US.appStrings." + stringId]) ? locale + ".appStrings." + stringId : locale.substring(0, 2) + ".appStrings." + stringId;
    const translation = i18next.isInitialized
        ? i18next.t([stringId + ".value", defaultString])//options removed
        : defaultString;

    return translation;
};